/* Add these styles to your existing CSS */
.rbc-toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.custom-event { /* custom-event style for unavailabledatesmodal */
  background-color: transparent !important;
  border: none !important;
  color: transparent !important;
}


.rbc-btn-group {
  display: flex;
}

.rbc-btn-group button {
  margin-right: 5px;
}

.btn-unavailable {
  margin: 10px;
  background-color: #f0ad4e;
}

.btn-unavailable:hover {
  background-color: #ec971f;
}

.calendar .rbc-day-slot .rbc-time-slot,
.calendar .rbc-day-bg {
  cursor: pointer;
}

.calendar .rbc-day-slot .rbc-time-slot:hover,
.calendar .rbc-day-bg:hover {
  background-color: #d8dcfd;
}

/* Existing styles */
.App {
  text-align: center;
  height: 100%;
  margin: 0;
  padding: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 200px);
  padding-top: 50px;
  box-sizing: border-box;
}

.calendar {
  width: 1200px;
}

.rbc-time-gutter {
  width: 77px; /* Adjust this value to ensure proper alignment */
}


/* Styles for the modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 800px;
  max-width: 80%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
  margin-top: 0;
  margin-bottom: 20px;
}
.register-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 80%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.register-modal-content h2 {
  margin-top: 0;
  margin-bottom: 20px;
}
.unavailable-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 80%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.unavailable-modal-content h2 {
  margin-top: 0;
  margin-bottom: 20px;
}
/* .change-password-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 80%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.change-password-modal-content h2 {
  margin-top: 0;
  margin-bottom: 20px;
}
 */

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: calc(100% - 10px);
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  background-color: #4caf50;
  color: white;
}

.btn-cancel {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  background-color: #f44336;
  color: white;
}

.homepage {
  text-align: center;
}







.login-container {
  margin: 20px auto;
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.btn-logout {
  background-color: #dc3545;
}

.btn-logout:hover {
  background-color: #c82333;
}

.btn-register {
  background-color: #007bff;
}

.btn-register:hover {
  background-color: #0056b3;
}

.btn-unavailable {
  background-color: #f0ad4e;
}

.btn-unavailable:hover {
  background-color: #ec971f;
}

.homepage-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f1f1f1;
}

.header-buttons {
  display: flex;
  gap: 10px;
}

.header-info {
  text-align: left;
}
.reservation-modal p {
  margin-bottom: 8px;
}

.reservation-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: 400px;
  z-index: 1000;
  display: block;
  border-radius: 8px;
}

.reservation-modal h2 {
  margin-bottom: 16px;
}

.reservation-detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}






